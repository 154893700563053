import Vue from "vue";
import VueRouter from "vue-router";
import access from "@/mixin/functions/access";

Vue.use(VueRouter)

const ifAdmin = (to, from, next) => {
    if (!access.hasRoleAdmin()) {
        next('/')
    } else {
        next();
    }
}

const ifTeacher = (to, from, next) => {
    if (access.hasTeacherUser()) {
        next()
    } else {
        next('/')
    }
}
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/HomePage'),
    },
    {
        name: 'home-page',
        path: '/page/:page',
        component: () => import('../pages/HomePage')
    },
    {
        name: 'add-course',
        path: '/add-course',
        component: () => import('../pages/Add-CoursePage'),
        beforeEnter: ifAdmin,
    },
    {
        name: 'addCategory',
        path: '/addCategory',
        component: () => import('../pages/AddCategoryPage.vue'),
        beforeEnter: ifAdmin,
    },
    {
        name: 'add-module',
        path: '/add-module',
        component: () => import('../pages/Add-ModulePage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/cabinet',
        component: () => import('../pages/CabinetPage')
    },
    {
        path: '/users-list',
        component: () => import('../pages/UsersListPage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/teachers-list',
        component: () => import('../pages/TeachersListPage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/teachers-list/page/:page',
        component: () => import('../pages/TeachersListPage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/user-list/page/:page',
        component: () => import('../pages/UsersListPage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/user-courses/:id',
        name: 'User-courses',
        component: () => import('../pages/UserCourses'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/edit-user',
        component: () => import('../pages/UserEditPage'),
    },
    {
        path: '/edit-admin-user/:id',
        component: () => import('../pages/UserEditAdminPage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/promo-code',
        component: () => import('../pages/PromoCodePage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/promo-codes',
        component: () => import('../pages/PromoCodeList'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/used-promo-code/:id',
        component: () => import('../pages/UsedPromoCodePage')
    },
    {
        path: '/promo-codes/page/:page',
        component: () => import('../pages/PromoCodeList'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/public-offer',
        component: () => import('../pages/PublicOffer')
    },
    {
        path: '/change-promo-code/:id',
        component: () => import('../pages/ChangePromo'),
        beforeEnter: ifAdmin,
    },
    {
        name: 'junior-course',
        path: '/course',
        component: () => import('../pages/CoursePage')
    },
    {
        path: '/add-student',
        component: () => import('../pages/AddStudent'),
        beforeEnter: ifAdmin
    },
    {
        path: '/add-student/:id',
        component: () => import('../pages/AddStudent'),
        beforeEnter: ifAdmin
    },
    {
        name: 'middle-course',
        path: '/course-middle',
        component: () => import('../pages/CourseMiddlePage')
    },
    {
        path: '/course-free',
        name: 'free-course',
        component: () => import('../pages/FreeCourse')
    },
    {
        path: '/course-archive/page/:page',
        component: () => import('../pages/ModulesPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/course-archive',
        component: () => import('../pages/ModulesPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/edit-module/:id',
        component: () => import('../pages/ChangeModule'),
        beforeEnter: ifAdmin
    },
    {
        path: '/courses/page/:page',
        component: () => import('../pages/CoursesPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/courses',
        component: () => import('../pages/CoursesPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/exist-course-types-list/page/:page',
        component: () => import('../pages/ExistingCourseTypes'),
        beforeEnter: ifAdmin
    },
    {
        path: '/exist-course-types-list',
        component: () => import('../pages/ExistingCourseTypes'),
        beforeEnter: ifAdmin
    },
    {
        path: '/exist-categories-list/page/:page',
        component: () => import('../pages/ExistingCategories'),
        beforeEnter: ifAdmin
    },
    {
        path: '/exist-categories-list',
        component: () => import('../pages/ExistingCategories'),
        beforeEnter: ifAdmin
    },
    {
        path: '/course-type',
        component: () => import('../pages/CourseTypePage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/change-course-type/:id',
        component: () => import('../pages/ChangeCourseType'),
        beforeEnter: ifAdmin
    },
    {
        path: '/change-category/:id',
        component: () => import('../pages/ChangeCategory.vue'),
        beforeEnter: ifAdmin
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/Login')
    },
    {
        path: '/my-course',
        component: () => import('../pages/MyCoursesPage')
    },
    {
        path: '/registration',
        component: () => import('../pages/RegistrationPage')
    },
    {
        path: '/course-students',
        component: () => import('../pages/CourseStudentsPage'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/course-teacher',
        component: () => import('../pages/TeacherCoursePage'),
        beforeEnter: ifTeacher,
    },
    {
        path: '/course-students/:courseId',
        component: () => import('../pages/CourseStudentsPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/course-stop-admission',
        component: () => import('../pages/CourseStopAdmission'),
        beforeEnter: ifAdmin
    },
    {
        path: '/course-left-comment',
        component: () => import('../pages/CourseLeftComment')
    },
    {
        path: '/add-comment',
        component: () => import('../pages/AddCommentPage')
    },
    {
        path: '/comments',
        component: () => import('../pages/CommentsPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/not-paid-students-courses',
        component: () => import('../pages/NotPaidStudentsCoursePage.vue'),
        beforeEnter: ifAdmin,
    },
    {
        path: '/not-paid-teacher-students-courses',
        component: () => import('../pages/NotPaidTeacherStudentsPage.vue'),
        beforeEnter: ifTeacher,
    },
    {
        path: '/comments/page/:page',
        component: () => import('../pages/CommentsPage'),
        beforeEnter: ifAdmin
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../pages/ForgotPassword'),
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('../pages/ResetPassword'),
    },
    {
        path: '/my-comments/page/:page',
        component: () => import('../pages/MyComments'),
    },
    {
        path: '/my-comments',
        component: () => import('../pages/MyComments')
    },
    {
        path: '/edit-comment/:id',
        component: () => import('../pages/EditMyComments')
    },
    {
        path: '/change-course/:id',
        component: () => import('../pages/ChangeCourse'),
        beforeEnter: ifAdmin
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('../pages/NotFoundPage.vue')
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash}
        }
        if (savedPosition) {
            return savedPosition;
        }
    },
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('accessToken') !== null

    if (isAuthenticated && to.name === 'login') {
        next({ name: 'home' })
    } else {
        next()
    }
})

export default router
